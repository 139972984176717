import React from "react";
import { Link, graphql } from "gatsby";

// import Bio from "../components/bio";
import Layout from "../components/layout";
import SEO from "../components/seo";
// import VerticalDisplayPanel from '../components/vertical-display-panel';
// import TitlePopover from '../components/title-popover';
//import HomeSketch from '../components/home-sketch'
// import Test from '../components/test'

import Box from '@material-ui/core/Box';
import { sizing } from '@material-ui/system';
import Grow from '@material-ui/core/Grow';
import Fade from '@material-ui/core/Fade';
// import Slide from '@material-ui/core/Slide';
// import Grid from '@material-ui/core/Grid';
// import Container from '@material-ui/core/Container';

// import Slide from 'react-reveal/Slide';
// import Zoom from 'react-reveal/Zoom';

// import { rhythm } from "../utils/typography"
// import mutedTheme from '../themes/muted-theme.js';
// import '../styles/site.css'
import useStyles from '../styles/useStyles.js';

import polySunflower from '../../content/assets/other-vector-art/sunflowerPoly.png';
// var ReactFitText = require('react-fittext');

// var logo = require('../../content/assets/logos/LVN-logo-beige-pointed.png');
// var logo = require('../../content/assets/LVN-logo-new-light.png');
//var logo = require('../../content/assets/LVN-logo-dark.png');
//var logo = require('../../content/assets/LVN-logo-full-dark.png');
var logo = require('../../content/assets/logos/LVN-logo-light-curved-new.png');
// var stars = require('../../content/assets/stars.jpg');
// var blossoms = require('../../content/assets/purple-blossoms-small.jpg');
// var orangeMoon = require('../../content/assets/orange-moon.jpg');
// var starSwirl = require('../../content/assets/star-swirl.jpg');
// var universe = require('../../content/assets/universe.jpg');
// var plants = require('../../content/assets/plants-1.jpg');
// var plants = require('../../content/assets/plants-5.jpg');
var developer = require('../../content/assets/featured-skills-images/developer-1.png');
//var writer = require('../../content/assets/featured-skills-images/writer-2.jpg');
var writer = require('../../content/assets/featured-skills-images/writer-2.png');
var floral = require('../../content/assets/floral-1.jpg');

class Home extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      show1: true,
    }
    this.handleScroll = this.handleScroll.bind(this);
  }
  handleScroll = e => {
    let element = e.target
    if (element.scrollHeight - element.scrollTop === element.clientHeight && this.state.show1 == false) {
      // do something at end of scroll
      this.setState({ show1: true, });
      console.log("tortellini");
      console.log(this.state.show1);
    }
  }
  render() {
    const { data } = this.props
    const siteTitle = data.site.siteMetadata.title
    //const posts = data.allMarkdownRemark.edges
    const classes = useStyles
    //const sketchWidth  = document.getElementsByClassName("main-content-block-horizontal")[0].offsetWidth
    //const divWidth = document.getElementsByClassName("main-content-block-horizontal")[0].offsetWidth;
    return (
      <Layout location={this.props.location} title={siteTitle}>
        <SEO
          title="Liz Nelson's Digital Portfolio"
          description='A place to showcase examples of digital media by Liz Nelson
         including graphic design like logos and illustrations, writing on a variety
         of topics, and creative coding projects'
        />
        <Box
          display='flex'
          className='light-page'
          style={{
            justifyContent: 'center',
            alignItems: 'center',
          }}
        >
          <Box
            id='home-page-block'
            bgcolor='primary.light'
            color='secondary.dark'
            style={{
              //background:'linear-gradient(to top, rgba(31,23,24,1) 0%, rgba(31,23,24,0.5) 40%, rgba(138,106,109,0.2) 100%)',
              background: `url(${floral})`,
              backgroundSize: 'cover',
              backgroundAttachment: 'fixed',
            }}
          >
            <Box
              className='block-header-gradient'
            >
            </Box>
          </Box>
          <Box
            className='vertical-container switch-container'
            id='vertical-container-home'
            style={{
              position: "absolute",
              zIndex: "100",
              //alignItems: 'center',
            }}
          >

            <Box
              className='half-page'
              //onScroll={this.handleScroll}
              id='full-page-mobile'
              style={{
                alignItems: 'center',
                //justifyContent: 'center',
                //border: "2px solid green", //for testing purposes
                // backgroundImage:`url(${blossoms})`,
                // backgroundSize: 'contain',
              }}
              color='secondary.dark'
            >
              <Fade
                in={true}
                timeout={2000}
              //id='logo-fade'
              >
                <Box
                  id='outer-logo-container'
                //bgcolor='primary.main'
                >
                  <Box
                    className='logo-container'
                    //bgcolor='primary.light'
                    color='secondary.main'
                  >
                    <img
                      className='logo'
                      src={logo}
                    />
                  </Box>
                </Box>
              </Fade>
              <Box
                color='primary.light'
                //bgcolor='primary.dark'
                id='home-hero-title'
              >
                <h2
                  className='alt-small-title'
                // style={{
                //   mixBlendMode: "darken",
                // }}
                >
                  a cross-disciplinary portfolio.
                </h2>

              </Box>
            </Box>
          </Box>
        </Box>
        {/*  <Box
           bgcolor='primary.light'
           className='no-margin-bottom'
           >*/}
        <Grow
          in={true}
          timeout={1000}
        >
          <Box
            className='feature-box'
            onScroll={this.handleScroll}
          >
            <Box
              //bgcolor='primary.dark'
              className='feature-title-box-left feature-title-box-mobile'
              color='secondary.green'
            >
              <h1
                className='alt-big-title feature-title '
              >
                Developer
              </h1>
            </Box>
            <Box
              className='feature-contents feature-contents-reverse'
            >
              <Box
                className='feature-description-box'
              >
                <Box
                  className='feature-description'
                >
                  <h2
                    className='alt-big-title feature-sub-heading-left feature-sub-heading-mobile '
                  >
                    full stack dev
                  </h2>
                  <br />
                  <p
                    className='feature-paragraph-left big-p'
                  >
                    Liz has a passion for UI. There's just something about bringing  the code to life in the form of engaging,
                    responsive interfaces and websites that sets her creative soul aflame. She uses this fiery passion to help
                    brands actualize their visions and give express herself through her own personal projects.
                    Experienced with Bootstrap, Material UI, React, and more.
                  </p>
                  <p
                    className='feature-paragraph-left big-p'
                  >
                    And what's a pretty face without substance behind it? Behind every UI is its well-oiled backend and, with
                    experience in full-stack development, object-oriented programming, and knowledge in several backend languages and frameworks
                    like Java and Spring Boot, C# and ASP.Net CORE, JavaScript and Node.js, Liz feels confident diving into
                    the logic behind the scenes.
                  </p>

                </Box>
              </Box>
              <Box
                className='feature-image-box'
                style={{
                  backgroundImage: `url(${developer})`,
                  // backgroundSize: 'cover',
                  // backgroundAttachment: 'fixed',
                }}
              >
              </Box>
            </Box>
          </Box>
        </Grow>

        <Grow
          in={true}
          timeout={1000}
        >
          <Box
            className='feature-box'
          >
            <Box
              //bgcolor='primary.dark'
              className='feature-title-box-right feature-title-box-mobile'
              color='secondary.green'
            >
              <h1
                className='alt-big-title feature-title '
              >
                Artist
              </h1>
            </Box>
            <Box
              className='feature-contents'
            >
              <Box
                className='feature-image-box'
                style={{
                  backgroundImage: `url(${polySunflower})`,
                  // backgroundSize: 'contain',
                  // backgroundAttachment: 'fixed',
                }}
              >
              </Box>
              <Box
                className='feature-description-box'
              >
                <Box
                  className='feature-description'
                >
                  <h2
                    className='alt-big-title feature-sub-heading-right feature-sub-heading-mobile '
                  >
                    graphic design+

                  </h2>
                  <br />
                  <p
                    className='feature-paragraph-right big-p'
                  >
                    Liz has created and designed graphics and marketing assets
                    for clients spanning a variety of industries and specialties.
                    She's experienced in creating logos, flyers, ad creative,
                    wireframes, custom icons, and more.
                  </p>
                </Box>
              </Box>
            </Box>
          </Box>
        </Grow>

        <Grow
          in={true}
          timeout={1000}
        >
          <Box
            className='feature-box'
          >
            <Box
              //bgcolor='primary.dark'
              className='feature-title-box-left feature-title-box-mobile'
              color='secondary.green'
            >
              <h1
                className='alt-big-title feature-title'
              >
                Writer
              </h1>
            </Box>
            <Box
              className='feature-contents feature-contents-reverse'
            >
              <Box
                className='feature-description-box'
              >
                <Box
                  className='feature-description'
                >
                  <h2
                    className='alt-big-title feature-sub-heading-left feature-sub-heading-mobile '
                  >
                    Copy Writing & Content Creation

                  </h2>
                  <br />
                  <p
                    className='feature-paragraph-left big-p'
                  >

                    Liz is an experienced copy writer and adept in editing for social media, blogs,
                    and advertising campaigns. Liz helps clients find and express their voice in an
                    authentic way that resonates with their audiences.
                  </p>
                </Box>
              </Box>
              <Box
                className='feature-image-box'
                style={{
                  backgroundImage: `url(${writer})`,
                  // backgroundSize: 'cover',
                  // backgroundAttachment: 'fixed',
                }}
              >
              </Box>
            </Box>

          </Box>
        </Grow>
      </Layout>
    )
  }
}

export default Home

export const pageQuery = graphql`
  query {
    site {
      siteMetadata {
        title
      }
    }
    allMarkdownRemark(sort: { fields: [frontmatter___date], order: DESC }) {
      edges {
        node {
          excerpt
          fields {
            slug
          }
          frontmatter {
            date(formatString: "MMMM DD, YYYY")
            title
            description
          }
        }
      }
    }
  }
`
