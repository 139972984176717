import { makeStyles } from '@material-ui/core/styles';

export const useStyles = makeStyles(theme => ({
  root: {
    flexGrow: 1,
  },
  menuButton: { 
    marginRight: theme.spacing(2),
  },
  title: {
    flexGrow: 1,
  },
  main: {
    background: '#E7E1E1',
    width: '100vw',
    minHeight: '90vh',
    display: 'flex',
    flexDirection: 'column',
  },
}));

export default useStyles;